<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <v-app>
        <v-app-bar
                fix
                app
                color="dark"
                dark
                class="z-index-top"
        >
            <a href="/">
                <v-img
                        alt="Vuetify Logo"
                        contain
                        src="@/assets/valley_logo.png"
                        transition="scale-transition"
                        width="60"
                />
            </a>
            <a href="/">
                <v-img
                        alt="Vuetify Name"
                        contain
                        min-width="100"
                        src="@/assets/valley_logo_text.png"
                        width="150"
                />
            </a>
            <nav class="navbar navbar-static-top hidden-md-and-down">
                <div class="navbar-custom2-menu" style="width: 1300px; margin: auto;">
                    <ul class="navbar-nav1 bottomMenu">
                        <li id=about class="list"><a  :class="{active : menuActive.isActive0}" href="/about/main">일번지 소개</a>
                            <ul class="items">
                                <li><a href="/about/main?pageId=1">인사말</a></li>
                                <li><a href="/about/main?pageId=2">둘러보기</a></li>
                                <li><a href="/about/main?pageId=3">배치도</a></li>
                                <li><a href="/about/main?pageId=4">오시는 길</a></li>
                                <!--<li><a href="/about/near">주변관광지</a></li>-->
                            </ul>
                        </li>
                        <li  id="place" class="list"><a :class="{active : menuActive.isActive1}" href="/place/main?pageId=0">시설안내</a>
                            <ul class="items">
                                <!--<li><a href="/place/campMap">배치도</a></li>-->
                                <!--<li><a href="/place/roomList">객실</a></li>
                                <li><a href="/place/campList">캠핑장</a></li>
                                <li><a href="/place/room/9">수영장</a></li>-->
                                <li><a href="/place/main?pageId=1">캠핑장</a></li>
                                <li><a href="/place/main?pageId=2">객실(펜션)</a></li>
                                <li><a href="/place/main?pageId=3">수영장</a></li>
                                <li><a href="/place/main?pageId=4">계곡전경</a></li>
                            </ul>
                        </li>
                        <li id="userGuide" class="list"><a :class="{active : menuActive.isActive2}"  href="/userGuide/main" >이용안내</a>
                            <ul class="items">
                                <li><a href="/userGuide/main">이용안내</a></li>
                                <li><a href="/userGuide/main?pageId=2">환불규정</a></li>
                                <li><a href="/userGuide/main?pageId=3">이용요금</a></li>
                                <!--<li><a href="/userGuide/useInfo">비상시 대책요령</a></li>-->
                                <li><a href="/userGuide/near">주변관광지</a></li>
                            </ul>
                        </li>
                        <!--style="color:#FFF176;"-->
                        <li id="reservation" class="list"><a :class="{active : menuActive.isActive3}"  href="/reservation/search" >실시간예약</a></li>
                        <li id="reservationConfirm" class="list"><a :class="{active : menuActive.isActive4}"  href="/reservation/resvConfirm">예약확인</a></li>
                        <li id="community" class="list"><a :class="{active : menuActive.isActive5}"  href="/community/notice">커뮤니티</a>
                            <ul class="items">
                                <li><a href="/community/notice">공지사항</a></li>
                                <li><a href="/community/review">이용후기</a></li>
                                <li><a href="/community/customer">문의하기</a></li>
                                <li><a href="/community/photo">포토갤러리</a></li>
                            </ul>
                        </li>
                        <li class="list"><a href="#">
                            <span class="material-icons" style="font-size:15px;">
                                call
                            </span>
                            <span style="margin:0px 10px; font-size: 15px;">010-3955-0677</span></a>
                        </li>
                    </ul>
                </div>
            </nav>
            <v-spacer></v-spacer>
            <div class="d-flex align-center">
                <v-app-bar-nav-icon @click.stop="drawer = !drawer"
                                    class="mt-1 hidden-lg-and-up float-right"></v-app-bar-nav-icon>
            </div>
        </v-app-bar>

        <v-navigation-drawer
                v-model="drawer"
                temporary
                :fixed="true"
                :width="300"
                right
        >
            <div style="margin-top:50px;"></div>

            <v-divider></v-divider>
            <v-list>
                <template v-for="(item, i) in items">
                    <!-- if it's a menu item with no children -->
                    <v-list-item
                            color="primary"
                            v-if="!item.subItem"
                            :key="i"
                            :to="item.route"
                            @click="log"
                    >
                        <v-list-item-icon>
                            <v-icon v-text="item.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title v-text="item.title"></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <!-- else if it has children -->
                    <v-list-group
                            v-else
                            :key="item.title"
                            v-model="item.active"
                            :prepend-icon="item.icon"
                            no-action
                    >
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title v-text="item.title"></v-list-item-title>
                            </v-list-item-content>
                        </template>

                        <v-list-item
                                v-for="subItem in item.subItem"
                                :key="subItem.title"
                                :to="subItem.route"
                        >
                            <v-list-item-content>
                                <v-list-item-title v-text="subItem.title"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                </template>
            </v-list>
        </v-navigation-drawer>

        <v-main>

            <main-pop-layer ref="mainPopLayer"></main-pop-layer>

            <router-view/>
            <v-btn
                    v-show="!isMobile"
                    fab
                    fixed
                    dark
                    bottom
                    right
                    @click="clickBtn_main(5)"
                    style="bottom:80px;"
                    color="blue lighten-1"
            >
                <v-icon>event_note</v-icon>
            </v-btn>
            <v-btn
                    v-show="isMobile"
                    fab
                    fixed
                    dark
                    bottom
                    right
                    @click="clickBtn_main(6)"
                    style="bottom:80px;"
                    color="blue lighten-1"
            >
                <v-icon>call</v-icon>
            </v-btn>
            <!--v-if="this.$store.state.admin.isLogin"-->
           <!-- <v-btn
                    v-if="isLogin"
                    fab
                    fixed
                    dark
                    bottom
                    right
                    @click="clickBtn_main(2)"
                    style="bottom:150px;"
            >
                <v-icon>mdi-monitor</v-icon>
            </v-btn>-->
            <v-btn
                    v-show="true"
                    fab
                    fixed
                    dark
                    bottom
                    right
                    @click="$vuetify.goTo(0)"
            >
                <v-icon>mdi-menu-up</v-icon>
            </v-btn>
        </v-main>

        <v-footer
                dark
                padless
        >
            <v-container>
                <v-row class="mt-2">
                    <v-btn
                            class="mx-4 white--text"
                            :to="'/place/main?pageId=0'">
                        시설안내
                    </v-btn>
                    <v-btn
                            class="mx-4 white--text"
                            @click="clickBtn_main(4)">
                        배치도
                    </v-btn>
                    <v-btn
                            class="mx-4 white--text"
                            @click="clickBtn_main(7)">
                        오시는길
                    </v-btn>
                    <v-btn
                            v-for="item in footItems"
                            :key="item.title"
                            class="mx-4 white--text"
                            :to="item.route"
                    >
                        {{item.title}}
                    </v-btn>

                   <!-- <v-btn
                            v-if="!isLogin"
                            :key="item.title"
                            class="mx-4 white&#45;&#45;text"
                            @click="clickBtn_main(1)">
                        관리자
                    </v-btn>
                    <v-btn
                            v-if="isLogin"
                            :key="item.title"
                            class="mx-4 white&#45;&#45;text"
                            @click="clickBtn_main(2)">
                        관리자 페이지
                    </v-btn>
                    <v-btn
                            v-if="isLogin"
                            :key="item.title"
                            class="mx-4 white&#45;&#45;text"
                            @click="clickBtn_main(3)">
                        관리자 로그아웃
                    </v-btn>-->
                </v-row>

                <v-row class="my-6 footer-custom">
                    <v-col cols="12" class="pt-1 pb-0">
                        {{homeInfo.saupNm}} |
                        {{homeInfo.saupAddr}}
                    </v-col>
                    <v-col cols="12" class="pt-1 pb-0">
                        대표:{{homeInfo.mstrNm}} (<span class="footer-custom-strong">{{homeInfo.mstrTel}}</span>)
                    </v-col>
                    <v-col cols="12" class="pt-1 pb-0">
                        <!--TEL:{{homeInfo.mstrTel}} |-->
                        <span class="footer-custom-strong">무통장 : {{accInfo.accBnkNm}} / {{accInfo.accNum}} / {{accInfo.accResvNm}}</span>
                    </v-col>
                    <v-col cols="12" class="pt-1 pb-0">
                        COPYRIGHT(C)2023 VALLEY1.CO.KR ALL RIGHTS RESERVED
                    </v-col>
                </v-row>
            </v-container>
        </v-footer>

    </v-app>
</template>

<script>
    import MainPopLayer from '../components/MainPopLayer'
    export default {

        name: 'HomeLayout',
        components: {MainPopLayer},
        data: () => ({
            drawer: false,
            group: null,
            item: 1,
            items: [
                {
                    title: '일번지 소개', icon: 'mdi-star', route: '/about/main',
                    /*subItem: [
                        {title: '인사말', route: '/about/main?pageId=1'},
                        {title: '둘러보기', route: '/about/main?pageId=2'},
                        {title: '배치도', route: '/about/main?pageId=3'},
                        {title: '오시는 길', route: '/about/main?pageId=4'},

                    ],*/
                },
                {
                    title: '시설안내', icon: 'mdi-image', route: '/place/main',
                    /*subItem: [
                        {title: '캠핑장', route: '/place/main?pageId=1'},
                        {title: '객실(펜션)', route: '/place/main?pageId=2'},
                        {title: '수영장', route: '/place/main?pageId=3'},
                        {title: '계곡전경', route: '/place/main?pageId=4'},
                    ]*/
                },
                {
                    title: '이용안내', icon: 'mdi-help-box', route: '/userGuide/main',
                    /*subItem: [
                        {title: '이용안내', route: '/userGuide/main'},
                        {title: '환불규정', route: '/userGuide/main?pageId=2'},
                        {title: '이용요금', route: '/userGuide/main?pageId=3'},
                        {title: '주변관광지', route: '/about/near'},
                    ]*/
                },
                {title: '실시간예약', icon: 'mdi-calendar-check', route: '/reservation/search'},
                {title: '예약확인', icon: 'mdi-calendar', route: '/reservation/resvConfirm'},
                {
                    title: '커뮤니티', icon: 'mdi-view-dashboard', route: '/community/notice',
                    /*subItem: [
                        {title: '공지사항', route: '/community/notice'},
                        {title: '이용후기', route: '/community/review'},
                        {title: '문의하기', route: '/community/customer'},
                        {title: '포토갤러리', route: '/community/photo'},
                    ]*/
                },
                {title: '주변관광지', icon: 'mdi-image', route: '/userGuide/near'},
            ],
            footItems: [
                {
                    title: '예약하기',
                    route: '/reservation/search'
                },
                {
                    title: '공지사항',
                    route: '/community/notice'
                },
                /*{
                    title: '오시는길',
                    route: '/about/main?pageId=4'
                },*/
            ],
            right: null,
            scroll: 0,
            scrollTarget: null,
            homeInfo: {
                saupNm: "",
                saupAddr: "",
                mstrNm: "",
                mstrTel: "",
            },
            accList: [],
            accInfo: {},
            isMobile: false,
            isLogin: false,
            layerInfo: {},
            menuActive: {
                isActive0: false,   // 일번지소개
                isActive1: false,   // 시설안내
                isActive2: false,   // 이용안내
                isActive3: false,   // 실시간예약
                isActive4: false,   // 내예약확인
                isActive5: false,   // 커뮤니티
                isActive6: false,
                isActive7: false,
                isActive8: false,
                isActive9: false,
            }
        }),
        mounted() {
            // 모바일 체크
            this.isMobile = this.$isMobile();


            if (this.$getCookie("AUTH") === 'true') {
                this.isLogin = true;
            } else if(this.$store.state.admin.loginInfo.homeNm !== undefined){
                this.isLogin = true;

            } else {
                this.isLogin = false;
                this.$deleteCookie("AUTH");
                this.$store.commit("admin/LOGOUT");
            }

            this.$store.dispatch("admin/getHomeInfo", {})
                .then((resp) => {
                    resp.message.usrPw = "";
                    this.homeInfo = resp.message;
                })
                .catch((err) => {
                })

            this.$store.dispatch("admin/getComn", {comnGubun: 'accList'})
                .then((resp) => {

                    if (resp.message !== undefined && resp.message.comnCont !== undefined) {
                        this.accList = JSON.parse(resp.message.comnCont);
                        this.accInfo = this.accList[0];
                    }
                })
                .catch((err) => {
                });

            this.getAccInfo();
            this.$nextTick(() => {
                // navbar setting
                var $firstMenu = $('.navbar-nav1'),
                    $header = $('.v-app-bar');

                var $topMenu_enter = $(".list > a, .items, .navbar-custom2-menu > .navbar-nav1 > li > ul > li");
                var $topMenu_leave = $(".v-app-bar");

                $topMenu_enter.mouseenter(function () {

                    $header.stop().animate({height: '250px'}, 300);
                    $header.css("background", "linear-gradient(#272727 100px, #272727 65px, #272727 )");
                });

                $topMenu_leave.mouseleave(function () {
                    $header.stop().animate({height: '64px'}, 300);
                    $header.css("background", "linear-gradient(#272727 100px, #272727  65px, #272727 )");
                });
            })

        },
        methods: {

            log() {
            },
            getAccInfo() {

                let ourl = document.referrer;
                let formdata = {
                    referrer: ourl,
                    url: document.documentURI,
                    accEng: "기타",
                    accWord: "",

                };

                if (ourl.length > 0) {
                    const url = new URL(ourl);
                    const hostname = url.hostname;
                    const urlParams = url.searchParams;

                    let isEng = false;

                    if (hostname.includes('naver')) {
                        formdata['accEng'] = 'naver';
                        formdata['accWord'] = urlParams.get('query');
                        isEng = true;
                    }
                    if (hostname.includes('google')) {
                        formdata['accEng'] = 'google';
                        formdata['accWord'] = urlParams.get('q');
                        isEng = true;
                    }
                    if (hostname.includes('daum')) {
                        formdata['accEng'] = 'daum';
                        formdata['accWord'] = urlParams.get('q');
                        isEng = true;
                    }

                    if (!isEng) { // 아무것도 없으면 기타
                        formdata['accEng'] = '기타';
                        formdata['accWord'] = '';
                    }

                }

                // 접속자 확인
                this.$store.dispatch("admin/getAcc", formdata)
                    .then((resp) => {

                    })
                    .catch((err) => {
                    });

            },
            clickBtn_main(val) {

                switch (val) {
                    case 1 : { // 관리자 로그인
                        let routeData = this.$router.resolve({path: '/admin/login'});
                        window.open(routeData.href, '_blank');
                        break;
                    }
                    case 2 : { // 관리자페이지
                        let routeData = this.$router.resolve({path: '/admin/mainPage'});
                        window.open(routeData.href, '_blank');
                        break;
                    }
                    case 3 : { // 로그아웃
                        this.$store.dispatch("admin/logout")
                            .then((resp) => {
                                setTimeout(() => {
                                    this.$deleteCookie("AUTH");
                                    this.$store.commit("admin/LOGOUT");
                                    //this.$router.push({path: "/"});
                                    location.reload()

                                }, 300)
                            })
                            .catch((err) => {
                            })
                        break;
                    }
                    case 4: {   // 배치도
                        /*let options = 'top=10, left=10, width=550, height=840, status=no, menubar=no, toolbar=no, resizable=no';
                        let route = '/popup/place/campMap';
                        let popNm = "배치도";
                        window.open(route, popNm, options);*/

                        //this.$refs.mainPopLayer.openPop('BAT');
                        location.href = '/about/main?pageId=3';



                        break;
                    }
                    case 5 : {
                        this.$router.push({path: "/reservation/search"});
                        break;
                    }
                    case 6 : {   // 전화걸기
                        //document.location.href='tel:010-7115-6413';
                        document.location.href = 'tel:' + this.homeInfo.mstrTel;
                        break;
                    }
                    case 7: {   // 오시는길
                        //this.$refs.mainPopLayer.openPop('WTC');
                        location.href = '/about/main?pageId=3';
                        break;
                    }

                }

            },
            closeLayer(IdName) {
                var pop = document.getElementById(IdName);
                pop.style.display = "none";
            },
            setMenuActive(){

                this.initMenuActive();

                let route = window.location.pathname.split('/');

                switch (route[1]) {
                    case "about": {
                        this.menuActive.isActive0 = true;
                        break;
                    }
                    case "place": {
                        this.menuActive.isActive1 = true;
                        break;
                    }
                    case "userGuide": {
                        this.menuActive.isActive2 = true;
                        break;
                    }
                    case "reservation": {
                        if(route[2].indexOf("resvConfirm") > -1){
                            this.menuActive.isActive4 = true;
                        } else {
                            this.menuActive.isActive3 = true;
                        }
                        break;
                    }
                    case "community": {
                        this.menuActive.isActive5 = true;
                        break;
                    }

                }

            },
            initMenuActive(){
                this.menuActive.isActive0 = false;
                this.menuActive.isActive1 = false;
                this.menuActive.isActive2 = false;
                this.menuActive.isActive3 = false;
                this.menuActive.isActive4 = false;
                this.menuActive.isActive5 = false;
            }
        },
        beforeUpdate(){
            // 메뉴 엑티브 설정
            this.setMenuActive();
        },

        watch: {
            group() {
                this.drawer = false
            },

        },
    };

</script>

<style>

</style>
