<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
            <div id="layerMap" class="layerPop">
                <table>
                    <tr>
                        <td>
                            <table>
                                <tr>
                                    <td>
                                        <div style="cursor: pointer; width: 100%; height: 100%;">
                                                <div class="layerBody">
                                                    <img
                                                            :src="layerInfo.roomImgRoute"
                                                            class="grey lighten-2"
                                                            style="width: 100%;"

                                                    />
                                                </div>

                                        </div>
                                    </td>
                                </tr>
                                <tr style="background-color: #eaeaea" align="center" class="layerBottom">
                                    <a href="#" @click="closeWin()" class="mx-3">닫기</a>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </div>
    </div>
</template>

<script>


    export default {
        name: 'MainPopLayer',
        data: () => ({
            layerInfo: [],
            isMobile: false,

        }),
        mounted() {

        },
        created() {

            //this.openPop()
            // 모바일 체크
            this.isMobile = this.$isMobile();

        },
        methods: {

            openPop(val) {


                let _this = this;

                    _this.$nextTick(() => {
                        return this.$store.dispatch("admin/getRoomImg", {roomId: val})
                            .then((resp) => {
                                setTimeout(() => {

                                    this.layerInfo = resp.message[0];
                                    setTimeout(() => {
                                        this.$nextTick(() => {
                                            var pop = document.getElementById('layerMap');

                                            let height = document.documentElement.scrollTop + 30;
                                            let left = "30px";
                                            //let height1 = document.querySelector('html').scrollTop;

                                            if(this.isMobile){
                                                left = "0px";
                                            }else {
                                                left = "30px";
                                            }

                                            pop.style.display = "grid";
                                            pop.style.top = height+"px";
                                            pop.style.left = left;
                                            //pop.style.transform = "translate(-50%, -50%)";

                                            pop.style.overflow = 'auto';


                                            $("#layerMap").fadeIn(400);
                                        });
                                    }, 300)


                                }, 300)
                            })
                            .catch((err) => {
                            })

                    })


            },
            closeWin(key) {

                $("#layerMap").hide();

            },

        },
        computed: {},
        updated: function () {  // 페이지 전부 생성후 자동 실행


        }
    }
</script>

<style>

</style>
